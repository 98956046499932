export enum SexTypeEnum {
    Male = 'male',
    Female = 'female'
}

export enum StatusTypeEnum {
    Pending = 'pending',
    Done = 'done',
    Rejected = 'rejected',
    No_continuation = 'no_continuation'
}