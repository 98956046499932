
import { Vue } from "vue-property-decorator";
import SidebarApp from '@/components/layout/sidebar-app/sidebar-app.vue';
import { layoutMixin } from '@/mixins/layout.mixin';
import { mapGetters, mapActions } from "vuex";
export default Vue.extend({
  name: "Layout",
  components: {
    SidebarApp,
  },
  mixins: [layoutMixin],
  data(){
    return {
      showmenu: false,
      isOpen: false,
      showSide: true
    }
  },
  computed: {
    ...mapGetters("layout",['getActiveSidebar']),
  },
  methods: {
    ...mapActions("layout", ['setActiveSidebar']),
    ...mapActions('agent', ['loadAgents']),

    toggleSidebar(activeSidebar: boolean) {
      if(activeSidebar !== true ){
        this.setActiveSidebar(true)
      }else{
        this.setActiveSidebar(false)
      }
    },
  },
  mounted(){
    this.loadAgents()
  }
});
