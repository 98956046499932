import { Context } from "@/plugins/shield/models/context";

const utils = {
  saveToken(context: Context): void {
    localStorage.setItem('shield-token', context.getContext().token)
  },

  parseJWT(token: any): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
                    .split('')
                    .map(function(c) {
                          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(''));

    return JSON.parse(jsonPayload);

  }
}

export default utils