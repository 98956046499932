import { render, staticRenderFns } from "./death-registration.vue?vue&type=template&id=4a2e5938&scoped=true"
import script from "./death-registration.vue?vue&type=script&lang=ts"
export * from "./death-registration.vue?vue&type=script&lang=ts"
import style0 from "./death-registration.scss?vue&type=style&index=0&id=4a2e5938&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a2e5938",
  null
  
)

export default component.exports