
  import {Vue} from 'vue-property-decorator';
  
  export default Vue.extend({
    name: 'FormSection',
    props:[ 
        'title'
    ],
    components: {
      
    },
    mixins: [
      
    ],
    data() {
      return {
        tabIndex: 0,
      }
    },
    computed: {
        
    },
    methods: {
      
    },
  });
  