export const layoutMixin = {
  computed: {
  },
  data() {
    return {
      sidebarItems: [
        {
          id: '/births',
          link: '/births',
          titleMenu: 'Déclarations de naissance',
          icon: 'child_friendly',
        },
        {
          id: '/deaths',
          link: '/deaths',
          titleMenu: 'Déclarations de décès',
          icon: 'church',
        },
        {
          id: '/administration',
          link: '/administration',
          titleMenu: 'Administration',
          icon: 'settings',
        },
      ],
    };
  },
  methods: {
  },
};