import { MutationTree } from 'vuex';
import { AgentState } from './types';
import { AgentModel } from '@/models/partners/agent/agent-model';

export const mutations: MutationTree<AgentState> = {
  setAgents(state, payload: Array<AgentModel>) {
    state.agents = payload;
  },

  setAgent(state, payload: AgentModel | undefined) {
    state.agent = payload;
  },

};
