import { MutationTree } from 'vuex';
import { HealthCenterState } from './types';
import { HealthCenterModel } from "@/models/partners/health-center/health-center-model";
import { HealthCenterUpdateDto } from '@/dto/partners/health-center.dto';

export const mutations: MutationTree<HealthCenterState> = {
  setHealthCenters(state, payload: Array<HealthCenterModel>) {
    state.healthCenters = payload;
  },

  addHealthCenter(state, payload: HealthCenterModel) {
      state.healthCenters.push(payload);
  },

  removeHealthCenter(state, payload: string) {
    const deleted_health_center: HealthCenterModel = state.healthCenters.find(({health_center_id}) => health_center_id === payload)
    state.healthCenters = state.healthCenters.filter(health_center => health_center !== deleted_health_center);
  },

  _setHealthCenters(state, payload: HealthCenterUpdateDto){
    const health_center: HealthCenterModel = state.healthCenters.find(({health_center_id}) => health_center_id === payload.health_center_id)
    state.healthCenters.splice(state.healthCenters.indexOf(health_center), 1, {
      ...health_center,
      health_center_name: payload.health_center_name,
      agent_lastname: payload.agent_lastname,
      agent_firstname: payload.agent_firstname,
      mobile: payload.mobile,
      township: payload.township,
      district: payload.district,
      address_1: payload.address_1,
      address_2: payload.address_2,
      town: payload.town,
      postal_code: payload.postal_code,
      country: payload.country,
    })
  },

  setHealthCenter(state, payload: HealthCenterModel | undefined) {
    state.healthCenter = payload;
  },

  setNameFilter(state, payload: string | null){
    state.nameFilter = payload
  },

};
