
import { Vue } from 'vue-property-decorator';
import moment from 'moment';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import deathRegister from '@/json/death_register.json';
import { mapGetters, mapActions } from 'vuex';
import { DeathModel } from '@/models/partners/death/death-model';
import StatContainer from '@/components/stat-container/stat-container.vue';
import StatCard from '@/components/stat-container/stat-card/stat-card.vue'
import DeathRegisterModal from '@/components/modals/partners/death/death-register-modal.vue'
import ImagePreviewModal from '@/components/modals/partners/image-preview-modal/image-preview-modal.vue';
import { DeclarationStat } from '@/models/partners/birth/birth-model';

export default Vue.extend({
    name: 'BirthRegistration',
    components: {
        TableFilter,
        TableView,
        DeathRegisterModal,
        ImagePreviewModal,
        StatContainer,
        StatCard
    },
    data() {
        return {
            deathRegisters: deathRegister,
            fields: [
                {   
                    key: "tracked_code", label: "Numéro de déclaration", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'  
                },
                { 
                    key: "health_center", label: "Centre santé", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'  
                },
                { 
                    key: "death", label: "Décédé", sortable: false,
                    formatter: (value: null, key: string, item: any) => {
                        return item.last_name_deceased +' '+ item.first_name_deceased
                    }, 
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { 
                    key: "contact_phone_number", label: "Téléphone", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { 
                    key: "death_date", label: "Date de décès", sortable: false,
                    formatter: (value: null, key: string, item: any) => {
                        return moment(item.death_date).format('DD-MM-YYYY') 
                    }, 
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { 
                    key: "dead_since", label: "Depuis", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { 
                    key: "register_status", label: "Statut", sortable: false,
                    tdClass: 'ellipsis'  
                },
                { 
                    key: "file_name", label: "Document", sortable: false,
                },
            ],
            processFilter: {
                hospital: 'Centre de santé',
                state: 'Etat',
                registerDate: 'Date de déclaration'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed:{
        ...mapGetters('death', ['getDeaths', 'getNameFilter', 'getStatutFilter',
                        'getDayLeftFilter', 'getHealthCenterFilter', 'getDeathStat']),
        filteredItems() {
            const filtered = this.getDeaths.filter((item: DeathModel) => {
                // Conditions de filtrage basées sur statusFilter, nameFilter, daysFilter
                const format = moment(item.created_at).format('DD-MM-YYYY')
                return (
                    (!this.getStatutFilter || item.status === this.getStatutFilter) &&
                    (
                        !this.getNameFilter || this.getNameFilter.trim() === '' ||
                        item.health_center.toLowerCase().includes(this.getNameFilter) ||
                        item.tracked_code.toLowerCase().includes(this.getNameFilter) ||
                        item.last_name_deceased.toLowerCase().includes(this.getNameFilter) ||
                        item.first_name_deceased.toLowerCase().includes(this.getNameFilter)

                    ) && 
                    (!this.getDayLeftFilter || moment().diff(moment(format, 'DD-MM-YYYY'), 'days') <= this.getDayLeftFilter) &&
                    (!this.getHealthCenterFilter || item.health_center === this.getHealthCenterFilter)
                );
            });
            return filtered;
        },
        total(): number{
            if(this.getDeathStat !== undefined){
                return this.getDeathStat.pending + this.getDeathStat.done 
                    + this.getDeathStat.no_continuation + this.getDeathStat.rejected
            }
            return 0
        },
        stats(): DeclarationStat{
            if(this.getDeathStat !== undefined){
                return this.getDeathStat
            }
            return {
                pending: 0,
                done: 0,
                rejected: 0,
                no_continuation: 0
            }
        },
    },
    methods: {
        ...mapActions('death', ['loadDeaths','setDeath', 'setNameFilter', 'setHealthCenterFilter', 'setStatutFilter', 'setDayLeftFilter']),
        ...mapActions('partner', ['loadFileUri']),
        showDeathRegisterDetail(payload: DeathModel){
            this.setDeath(payload)
            this.$bvModal.show('death-register-detail')
        },
        imageLinkClicked(payload: DeathModel){
            this.setDeath(payload)
            this.$bvModal.show('image-preview-detail-death')
        },
        onSearchSet(payload: string){
            this.setNameFilter(payload.toLowerCase())
        },
        onDayLeftSet(payload: string){
            this.setDayLeftFilter(payload)
        },
        onStateSet(payload: string){
            this.setStatutFilter(payload)
        },
        onHealthCenterFilter(payload: string){
            this.setHealthCenterFilter(payload)
        },
        openInNewBlank(payload: DeathModel){
            this.loadFileUri(payload.tracked_code)
        }
    },
    mounted(){
      this.loadDeaths()
    }
});
