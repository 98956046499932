import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/agent/getters";
import {actions} from "@/store/modules/agent/actions";
import {mutations} from "@/store/modules/agent/mutations";
import { AgentState } from "./types";
export const state: AgentState = {
    agents: [],
    agent: undefined,
};

export const agent: Module<AgentState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};