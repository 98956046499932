import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { HealthCenterState } from './types'
import { HealthCenterModel } from "@/models/partners/health-center/health-center-model";

export const getters: GetterTree<HealthCenterState, RootState> = {
    
    getHealthCenters(state): Array<HealthCenterModel> {
        return state.healthCenters
    },

    getHealthCenter(state): HealthCenterModel | undefined{
        return state.healthCenter
    },
    
    getNameFilter(state): string | null{
        return state.nameFilter
    },
};