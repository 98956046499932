
import { Vue } from 'vue-property-decorator';
import moment from 'moment';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import PartnerAdminCreateModal from '@/components/modals/partners/partner-admin/partner-admin-create/partner-admin-create.vue';
import PartnerAdminUpdateModal from '@/components/modals/partners/partner-admin/partner-admin-update/partner-admin-update.vue';
import partnersAdmin from '@/json/partners_admin.json';
import { HealthCenterModel } from '@/models/partners/health-center/health-center-model';
import { mapGetters, mapActions } from 'vuex';
export default Vue.extend({
    name: 'PartnersAdmin',
    components: {
        TableFilter,
        TableView,
        PartnerAdminCreateModal,
        PartnerAdminUpdateModal
    },
    data() {
        return {
            partnersAdmins: partnersAdmin,
            fields: [
                { 
                    key: "health_center_code", label: "ID", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'
                },
                { 
                    key: "health_center_name", label: "Centre de santé", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'
                },
                { 
                    key: "health_center_type", label: "Type", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'
                },
                { 
                    key: "trigramme", label: "Trigramme", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'
                },
                { 
                    key: "created_at", label: "Date création", sortable: false,
                    formatter: (value: null, key: string, item: any) => {
                        return moment(item.created_at).format('DD-MM-YYYY')
                    },
                    tdClass: 'ellipsis', thClass: 'ellipsis'  
                },
                { 
                    key: "updated_at", label: "Date MAJ", sortable: false,
                    formatter: (value: null, key: string, item: any) => {
                        return item.updated_at && moment(item.updated_at).format('DD-MM-YYYY')
                    },
                    tdClass: 'ellipsis', thClass: 'ellipsis'  
                },
                { 
                    key: "actions", label: "Actions", sortable: false,
                    tdClass: 'ellipsis', thClass: 'ellipsis'
                },
            ],
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed:{
        ...mapGetters('healthCenter', ['getHealthCenters', 'getHealthCenter', 'getNameFilter']),
        filteredItems() {
            const filtered = this.getHealthCenters.filter((item) => {
                return (
                    (
                        !this.getNameFilter || this.getNameFilter.trim() === '' ||
                        item.health_center_name.toLowerCase().includes(this.getNameFilter) ||
                        item.health_center_code.toLowerCase().includes(this.getNameFilter) ||
                        item.health_center_type.toLowerCase().includes(this.getNameFilter) ||
                        item.trigramme.toLowerCase().includes(this.getNameFilter)

                    )
                );
            });
            return filtered;
        },
    },
    methods: {
        ...mapActions('healthCenter', ['loadHealthCenters', 'setHealthCenter', 'deletaHealthCenter', 'setNameFilter']),
        onCreate(){
            this.$bvModal.show('partner-admin-create')
        },
        onUpdate(item: HealthCenterModel){
            this.setHealthCenter(item)
            this.$bvModal.show('partner-admin-update')
        },
        deleteHealthCenter(item: HealthCenterModel){
            this.deletaHealthCenter(item.health_center_id)
        },
        onSearchSet(payload: string){
            this.setNameFilter(payload.toLowerCase())
        }
    },
    mounted(){
        this.loadHealthCenters()
    }
});
