import { StatusTypeEnum } from '@/common/enums';
import moment from 'moment';
import { BirthModel } from '@/models/partners/birth/birth-model';
import { DeathModel } from '@/models/partners/death/death-model';
export const appMixin = {
    computed: {
    },
    data() {
      return {
        minDay: 20,
        middleDay: 35,
        maxDay: 45,
        deathMinDay: 5,
        deathMiddleDay: 10,
        deathMaxDay: 15
      };
    },
    methods: {
        partnerStatutTypeFormat(payload: string): string {
            switch (payload) {
                case 'pending':
                    return 'En attente';
                case 'done':
                    return 'Traité';
                case 'rejected':
                    return 'Rejeté'
                default:
                    return 'Sans suite';
            }
        },
        dayLeftVariante(payload: number): string{
            if((this.minDay < payload) && (payload <= this.middleDay)){
              return 'warning'
            }
            if(payload > this.middleDay){
              return 'danger' 
            }
            return 'secondary'
        },
        deathDayLeftVariante(payload: number): string{
          if((this.deathMinDay < payload) && (payload <= this.deathMiddleDay)){
            return 'warning'
          }
          if(payload > this.deathMiddleDay){
            return 'danger' 
          }
          return 'secondary'
      },
        statusVariante(payload: string): string{
            if(payload === StatusTypeEnum.Done){
              return 'success'
            }
            if(payload === StatusTypeEnum.Rejected){
              return 'danger'
            }
            if(payload === StatusTypeEnum.No_continuation){
              return 'secondary'
            }
            return 'warning'
        },
        dateCount(startDate: string, endDate: string | null): number{
          if(!endDate){
            endDate = moment().format()
          }
          const formatStartDate = moment(startDate).format('DD-MM-YYYY')
          const formatEndDate = moment(endDate).format('DD-MM-YYYY')
          return moment(formatEndDate, 'DD-MM-YYYY').diff(moment(formatStartDate, 'DD-MM-YYYY'), 'days')
        },
        sortBirthData(payload: BirthModel[]): BirthModel[]{
          return payload.sort((a, b) => {
              return new Date(a.birthdate).getTime() - new Date(b.birthdate).getTime();
          })
        },
        sortDeathData(payload: DeathModel[]): DeathModel[]{
          return payload.sort((a, b) => {
              return new Date(a.death_date).getTime() - new Date(b.death_date).getTime();
          })
        },

    },
  };