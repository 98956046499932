
  import {Vue} from 'vue-property-decorator';
  import {HealthCenterType, HealthCenterStatus} from '@/common/api.enums'
  import FormSection from '@/components/modals/form-section/form-section.vue'; 
  import FormInput from '@/components/form/form-input/form-input.vue';
  import FormSelect from '@/components/form/form-select/form-select.vue';
  import {mapGetters, mapActions} from "vuex";
  import {HealthCenterUpdateDto} from '@/dto/partners/health-center.dto'
  
  export default Vue.extend({
    name: 'PartnerAdminUpdateModal',
    props: [
        'id',
        'reference',
        'title',
    ],
    components: {
      FormSection,
      FormInput,
      FormSelect,
    },
    mixins: [
      
    ],
    data() {
      return {
        data: 4,
        tabIndex: 0,
        value: '',
        disable: true,
        form: {
          health_center_name: '',
          health_center_type: '',
          trigramme: '',
          agent_firstname: '',
          agent_lastname: '',
          mobile: '',
          township: '',
          district: '',
          address_1: '',
          address_2: '',
          town: '',
          postalCode: '',
          country: '',
        },
        sexSelected: '',
        healthCenterTypeOptions: [
          { text: 'Hôpital', value: HealthCenterType.hopital },
          { text: 'Centre médicaux sociale', value: HealthCenterType.cms }
        ],
        healthCenterTypeStatus: [
        { text: 'Active', value: HealthCenterStatus.Active },
        { text: 'Fermé', value: HealthCenterStatus.Close }
      ]
      }
    },
    computed: {
        ...mapGetters('partner', ['getBirth']),
        ...mapGetters('healthCenter', ['getHealthCenter'])
    },
    methods: {
      ...mapActions('partner', ['updateBirthStatus']),
      ...mapActions('healthCenter', ['updateHealthCenter']),
      onHealthCenterUpdated(){
        const healthCenter: HealthCenterUpdateDto = {
          health_center_id: this.getHealthCenter.health_center_id,
          health_center_name: this.form.health_center_name,
          agent_lastname: this.form.agent_lastname,
          agent_firstname: this.form.agent_firstname,
          mobile: this.form.mobile,
          township: this.form.township,
          district: this.form.district,
          address_1: this.form.address_1,
          address_2: this.form.address_2,
          town: this.form.town,
          postal_code: this.form.postalCode,
          country: this.form.country,
        }
        this.updateHealthCenter(healthCenter)
        this.$bvModal.hide('partner-admin-update')
      },
      inputHealthCenterNameSet(payload: string){
        this.form.health_center_name = payload
      },
      inputHealthCenterTypeSet(payload: string){
        this.form.health_center_type = payload
      },
      inputHealthCenterStatusSet(payload: string){
        this.form.healthCenterStatus = payload
      },
      inputTrigrammeSet(payload: string){
        this.form.trigramme = payload
      },
      inputAgentLastnameSet(payload: string){
        this.form.agent_lastname = payload
      },
      inputAgentFirstNameSet(payload: string){
        this.form.agent_firstname = payload
      },
      inputMobileNumberSet(payload: string){
        this.form.mobile = payload
      },
      inputDistrictSet(payload: string){
        this.form.district = payload
      },
      inputTownshipSet(payload: string){
        this.form.township = payload
      },
      inputAddressOneSet(payload: string){
        this.form.address_1 = payload
      },
      inputAddressTwoSet(payload: string){
        this.form.address_2 = payload
      },
      inputTownSet(payload: string){
        this.form.town = payload
      },
      inputBpSet(payload: string){
        this.form.postalCode = payload
      },
      inputCountrySet(payload: string){
        this.form.country = payload
      },
      onEdit(){
        this.disable = !this.disable
      },
      modalStart(){
        this.form = this.getHealthCenter
        this.disable = true
      }
    },
    mounted() {
      this.modalStart()
    },
  });
  