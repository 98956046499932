import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { DeathState } from './types'
import { DeclarationStat } from "@/models/partners/birth/birth-model";
//import { DeathModel } from "@/models/partners/death/death-model";

export const getters: GetterTree<DeathState, RootState> = {
    
    getDeaths(state): Array<any> {
        return state.deaths
    },

    getDeath(state): any | undefined{
        return state.death
    },

    getDeathFileUri(state): string | undefined{
        return state.deathFileUri
    },

    getDeathsHealthCenterName(state): Array<string>{
        return state.deathsHealthCenterName
    },

    getStatutFilter(state): string | null{
        return state.statutFilter
    },

    getNameFilter(state): string | null{
        return state.nameFilter
    },

    getDayLeftFilter(state): number | null{
        return state.dayLeftFilter
    },

    getHealthCenterFilter(state): string | null{
        return state.healthCenterFilter
    },

    getDeathStat(state): DeclarationStat | undefined{
        return state.deathStats
    }
};