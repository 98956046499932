import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { NeighborhoodState } from './types'
import { NeighborhoodModel } from "@/models/partners/neighborhood/neighborhood-model";

export const getters: GetterTree<NeighborhoodState, RootState> = {
    
    getNeighborhoods(state): Array<NeighborhoodModel> {
        return state.neighborhoods
    },

    getNeighborhood(state): NeighborhoodModel | undefined{
        return state.neighborhood
    },

    getNameFilter(state): string | null{
        return state.nameFilter
    },
};