
  import {Vue} from 'vue-property-decorator';
  import { TYPE } from "vue-toastification";
  import moment from 'moment';
  import FormSection from '../../form-section/form-section.vue'; 
  import FormInput from '@/components/form/form-input/form-input.vue';
  import FormRadio from '@/components/form/form-radio/form-radio.vue';
  import { DeathUpdateDto } from '@/dto/partners/death.dto';
  import { StatusTypeEnum } from '@/common/enums'
  import {mapGetters, mapActions} from "vuex";
  import { appMixin } from '@/mixins/app.mixin';
  
  export default Vue.extend({
    name: 'DeathRegisterModal',
    props: [
        'id',
        'reference',
        'title',
    ],
    mixins: [appMixin],
    components: {
      FormSection,
      FormInput,
      FormRadio
    },
    data() {
      return {
        data: 4,
        tabIndex: 0,
        value: '',
        sexSelected: '',
        sexOptions: [
          { text: 'Masculin', value: 'male' },
          { text: 'Feminin', value: 'female' }
        ],
        status: '',
        tracked_code: '',
        deathDateFormat: '',
        deathInfo: {
          health_center: '',
          agent_function: '',
          chief_service: '',
          declarant: '',
          tracked_code: '',
          applicant_name: '',
          applicant_telephone: '',
          applicant_identifier: '',
          applicant_relationship: '',
          last_name_deceased: '',
          first_name_deceased: '',
          age_deceased: '',
          function_of_deceased: '',
          contact_phone_number: '',
          other_contact: '',
          death_date: '',
          reason_for_admission: '' ,
          sex: '',
          status: '',
          neighborhood: '',
          address: '',
        }
      }
    },
    computed: {
        ...mapGetters('death', ['getDeath', 'getDeathStat']),
        ...mapGetters('agent', ['getAgents']),
        declaredAgent(): string{
          const findAgent= this.getAgents.find((agent) => {
            return this.getDeath.declarant === agent.agent_id
          })
          return findAgent.name
        },
        serviceHeadmaster(): string{
          const findAgent= this.getAgents.find((agent) => {
            return this.getDeath.chief_service === agent.agent_id
          })
          return findAgent.name
        },
    },
    methods: {
      ...mapActions('death', ['updateDeathStatus', 'setDeathStat']),
      constructUpdateVar(status: string): DeathUpdateDto{
        let deathStatus = StatusTypeEnum.Rejected
        if(status === 'done'){
          deathStatus = StatusTypeEnum.Done
        }
        return {
            death_id: this.getDeath.death_id,
            status: deathStatus
        }
      },
      deathCaseTreated(){
        const death: DeathUpdateDto = this.constructUpdateVar('done')
        this.updateDeathStatus(death).then((response)=>{
          if(response===true){
            Vue.$toast("La déclaration de décès a été traité avec succès!")
          }
          else{
            Vue.$toast("Une erreur s'est produite. "+
            "L'action de traitement de la déclaration de décès n'a pas été éffectué!", { type: TYPE.ERROR });
          }
        })
        this.$bvModal.hide('death-register-detail')
      },
      deathCaseClosed(){
        const death: DeathUpdateDto = this.constructUpdateVar('rejected')
        this.updateDeathStatus(death).then((response)=>{
          if(response===true){
            this.setDeathStat(
              {
                ...this.getDeathStat,
                pending: this.getDeathStat.pending--,
                rejected: this.getDeathStat.rejected++
              }
            )
            Vue.$toast("La déclaration de décès a été rejeté avec succès!")
          }
          else{
            Vue.$toast("Une erreur s'est produite. "+
            "L'action de clôture de la déclaration de décès n'a pas été éffectué!", { type: TYPE.ERROR });
          }
        })
        this.$bvModal.hide('death-register-detail')
      },
      modalStart(){
        const dateCountValue = this.dateCount(this.getDeath.death_date, this.getDeath.updated_at)
        this.data = dateCountValue > this.deathMaxDay ? '+'+ this.deathMaxDay : dateCountValue
        this.tracked_code = this.getDeath.tracked_code
        this.deathDateFormat = moment(this.getDeath.death_date).format('DD-MM-YYYY')
        this.deathInfo = this.getDeath
      },
      imageLinkClick(){
        const url = this.getBirth.file_uri;
        const newTab = window.open(url, "_blank");
        newTab.focus();
      }
    },
  });
  