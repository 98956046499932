import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { AgentState } from './types'
import { AgentModel } from "@/models/partners/agent/agent-model";

export const getters: GetterTree<AgentState, RootState> = {
    
    getAgents(state): Array<AgentModel> {
        return state.agents
    },

    getDeath(state): AgentModel | undefined{
        return state.agent
    },
};