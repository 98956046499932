import { HttpBaseService } from '@/services/httpBase.service';
import { partnerServiceApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { BirthModel, DeclarationStat } from '@/models/partners/birth/birth-model';
import { BirthUpdateDto } from '@/dto/partners/birth.dto';
import { HealthCenterCreateDto, HealthCenterUpdateDto } from '@/dto/partners/health-center.dto';
import { HealthCenterModel } from '@/models/partners/health-center/health-center-model';
import qs from 'qs';
import { DeathUpdateDto } from '@/dto/partners/death.dto';
import { DeathModel } from '@/models/partners/death/death-model';


export class deathService extends HttpBaseService {
  private static classInstance?: deathService;
  static apiBaseUrl = partnerServiceApiUrl;

  constructor(token: string) {
    super(token, deathService.apiBaseUrl);
  }

  public static getInstance(token: string): deathService {
    if (!this.classInstance) {
      this.classInstance = new deathService(token);
    }

    return this.classInstance;
  }

  public async loadDeaths(payload:string): Promise<ApiResponse<[]>> {
    const apiResponse = new ApiResponse<[]>();
    const requestParam = {
      "tenant": payload,
      "health_center_code": ""
    }
    return await this.instance
      .post('/death/search', requestParam)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadDeathByTrackedCode(payload: string): Promise<ApiResponse<BirthModel>> {
    const apiResponse = new ApiResponse<BirthModel>();
    return this.instance
      .get(`/death/get/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadDeathStats(): Promise<ApiResponse<DeclarationStat>> {
    const apiResponse = new ApiResponse<DeclarationStat>();
    return await this.instance
      .get('/death/global-status')
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadDeathFileUri(payload: string): Promise<ApiResponse<string>> {
    const apiResponse = new ApiResponse<string>();
    return await this.instance
      .get(`/media/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public updateDeathStatus(payload: DeathUpdateDto): Promise<ApiResponse<DeathModel>> {
    const apiResponse = new ApiResponse<DeathModel>();
    return this.instance
      .put('/death/update', payload)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}