import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/partner/partner-getters";
import {actions} from "@/store/modules/partner/partner-actions";
import {mutations} from "@/store/modules/partner/partner-mutations";
import { PartnerState } from "./types";
export const state: PartnerState = {
    births: [],
    birth: undefined,
    statutFilter: null,
    nameFilter: null,
    dayLeftFilter: null,
    healthCenterFilter: null,
    birthsHealthCenterName: [],
    birthStats: undefined,
    birthFileUri: undefined
};

export const partner: Module<PartnerState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};