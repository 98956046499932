import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { NeighborhoodState } from './types';
import { NeighborhoodCreateDto, NeighborhoodUpdateDto } from '@/dto/partners/neighborhood.dto';
import { NeighborhoodModel } from '@/models/partners/neighborhood/neighborhood-model';
import { neighborhoodService } from '@/services/neighborhood.service';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";


export const actions: ActionTree<NeighborhoodState, RootState> = {
  loadNeighborhoods(context) {
    neighborhoodService
      .getInstance(this.getters.getUser.access_token)
      .loadNeighborhoods()
      .then((response) => {
        if (response.data) {
          context.dispatch('setNeighborhoods', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  createNeighborhood(context, payload: NeighborhoodCreateDto) {
    neighborhoodService
      .getInstance(this.getters.getUser.access_token)
      .createNeighborhood(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch('_setNeighborhoods', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  updateNeighborhood(context, payload: NeighborhoodUpdateDto){
    return neighborhoodService
      .getInstance(this.getters.getUser.access_token)
      .updateNeighborhood(payload)
      .then((response) => {
        if (response.apiError) {
          console.log('error');
          return false
        }else{
          context.commit('_setNeighborhood', response.data)
          return true
        }
      });
  },

  setNeighborhoods(context, payload: Array<NeighborhoodModel>) {
    context.commit('setNeighborhoods', payload);
  },

  _setNeighborhoods(context, payload: NeighborhoodModel | undefined) {
    context.commit('_setNeighborhoods', payload);
  },

  _setNeighborhood(context, payload: NeighborhoodModel | undefined) {
    context.commit('_setNeighborhood', payload);
  },

  setNeighborhood(context, payload: NeighborhoodModel | undefined){
    context.commit('setNeighborhood', payload);
  },

  setNameFilter(context, payload: string | null){
    context.commit('setNameFilter', payload);
  },
};
