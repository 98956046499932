export enum ProcessCaseStatusEnum {
    Opened = 'opened',
    InProgess = 'In Progress',
    Closed =  'closed'
}

export enum FormType {
    Client = 'ClientForm',
    Work = 'WorkForm',
}

export enum TypeOptions {
    Text = 'text',
    Num = 'number',
    Date = 'date',
    Time = 'time',
    Select = 'select'
}

export enum SexTypeEnum {
    Male = 'male',
    Female = 'female'
}

export enum StatusTypeEnum {
    Pending = 'pending',
    Done = 'done',
    Rejected = 'rejected',
    No_continuation = 'no_continuation'
}

export enum HealthCenterType {
    hopital = 'HPT',
    cms = 'CMS'
}

export enum HealthCenterStatus {
    Active = 'active',
    Close = 'fermé'
}