
import { Vue } from 'vue-property-decorator';
import NavbarApp from '@/components/layout/navbar-app/navbar-app.vue';

export default Vue.extend({
  name: 'App',
  components: {
    NavbarApp,
  },
  methods: {},
})
