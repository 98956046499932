
import { Vue } from "vue-property-decorator";
export default Vue.extend({
    name: 'FormRadio',
    props: [
        'label',
        'inputId',
        'inputName',
        'value',
        'disabled',
        'validationData',
        'labelAlign',
        'size',
        'placeholder',
        'labelCols',
        'type',
        'required',
        'options',
        'ariaDescribedby'
    ],
    data() {
        return {
            data: this.value,
            inputState: '',
            radioOptions: this.options
        }
    },
    computed: {
        state(): boolean | null {
            if (this.inputState === 'true'){
                return true
            }else if(this.inputState === 'false'){
                return false
            }
            return null
        }
    },
    methods: {
        onChangeValue () {
            this.$emit('onChange', this.data)
        },
        validateState(state: boolean): boolean{
            return state
        }
    },
    watch: {
        'validationData':  function validateInput(state: string){
                    this.inputState = state
                },
        data() {
            console.log('data func : ', this.data)
            this.$emit("input", this.data)
        },
        value() {
            if(this.value){
                this.data = this.value
            }
        }
    },
    mounted(){
        console.log('mounted value : ',this.value)
    }
})
