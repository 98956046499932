import { HttpBaseService } from '@/services/httpBase.service';
import { partnerServiceApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { NeighborhoodModel } from '@/models/partners/neighborhood/neighborhood-model';
import { NeighborhoodCreateDto, NeighborhoodUpdateDto } from '@/dto/partners/neighborhood.dto';

export class neighborhoodService extends HttpBaseService {
  private static classInstance?: neighborhoodService;
  static apiBaseUrl = partnerServiceApiUrl;

  constructor(token: string) {
    super(token, neighborhoodService.apiBaseUrl);
  }

  public static getInstance(token: string): neighborhoodService {
    if (!this.classInstance) {
      this.classInstance = new neighborhoodService(token);
    }

    return this.classInstance;
  }

  public createNeighborhood(payload: NeighborhoodCreateDto): Promise<ApiResponse<NeighborhoodModel>> {
    const apiResponse = new ApiResponse<NeighborhoodModel>();
    const request = [payload]
    return this.instance
      .post('/neighborhood/create', request)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadNeighborhoods(): Promise<ApiResponse<[]>> {
    const apiResponse = new ApiResponse<[]>();
    return await this.instance
      .get('/neighborhoods')
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadNeighborhoodById(payload: string): Promise<ApiResponse<NeighborhoodModel>> {
    const apiResponse = new ApiResponse<NeighborhoodModel>();
    return this.instance
      .get(`/neighborhood/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public updateNeighborhood(payload: NeighborhoodUpdateDto): Promise<ApiResponse<NeighborhoodModel>> {
    const apiResponse = new ApiResponse<NeighborhoodModel>();
    return this.instance
      .put('/neighborhood/update', payload)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public deleteNeighborhood(payload: string): Promise<ApiResponse<NeighborhoodModel>> {
    const apiResponse = new ApiResponse<NeighborhoodModel>();
    return this.instance
      .delete(`/health-center/delete/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

}
