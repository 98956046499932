import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { HealthCenterState } from './types';
import { partnerService } from '@/services/partner.service';
import { HealthCenterCreateDto, HealthCenterUpdateDto } from '@/dto/partners/health-center.dto';
import { HealthCenterModel } from '@/models/partners/health-center/health-center-model';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";


export const actions: ActionTree<HealthCenterState, RootState> = {
  loadHealthCenters(context) {
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .loadHealthCenters()
      .then((response) => {
        if (response.data) {
          context.dispatch('setHealthCenters', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadHealthCenter(context, payload: string) {
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .loadHealthCenterById(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch('setHealthCenter', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  createHealthCenter(context, payload: HealthCenterCreateDto){
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .createHealthCenterPartner(payload)
      .then((response) => { 
        if (response.data) {
          context.commit('addHealthCenter', response.data)
          Vue.$toast("Centre de santé créé avec succès!");
        }
        if (response.apiError) {
          Vue.$toast("Une erreur s'est produite lors de la création du centre de santé!", { type: TYPE.ERROR });
        }
      });
  },  

  updateHealthCenter(context, payload: HealthCenterUpdateDto){
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .updateHealthCenter(payload)
      .then((response) => {
        if (response.apiError) {
          Vue.$toast("Une erreur s'est produite lors de la modification du centre de santé!", { type: TYPE.ERROR });
        }else{
          context.commit('_setHealthCenters', payload)
          Vue.$toast("Centre de santé modifié avec succès!");
        }
      });
  },

  deletaHealthCenter(context, payload: string){
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .deleteHealthCenter(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch('removeHealthCenter', payload)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  setHealthCenters(context, payload: Array<HealthCenterModel>) {
    context.commit('setHealthCenters', payload);
  },

  setHealthCenter(context, payload: HealthCenterModel | undefined) {
    context.commit('setHealthCenter', payload);
  },

  setNameFilter(context, payload: string | null){
    context.commit('setNameFilter', payload);
  },
};
