
import {Vue} from 'vue-property-decorator';
import {HealthCenterType, HealthCenterStatus} from '@/common/api.enums'
import FormSection from '@/components/modals/form-section/form-section.vue'; 
import FormInput from '@/components/form/form-input/form-input.vue';
import FormSelect from '@/components/form/form-select/form-select.vue';
import {mapGetters, mapActions} from "vuex";
import {HealthCenterCreateDto} from '@/dto/partners/health-center.dto'

export default Vue.extend({
  name: 'PartnerAdminCreateModal',
  props: [
      'id',
      'reference',
      'title',
  ],
  components: {
    FormSection,
    FormInput,
    FormSelect,
  },
  mixins: [
    
  ],
  data() {
    return {
      data: 4,
      tabIndex: 0,
      value: '',
      form: {
        healthCenterName: '',
        healthCenterType: '',
        healthCenterStatus: '',
        trigramme: '',
        agentFirstname: '',
        agentLastname: '',
        mobile: '',
        township: '',
        district: '',
        address1: '',
        address2: '',
        town: '',
        postalCode: '',
        country: '',
      },
      sexSelected: '',
      healthCenterTypeOptions: [
        { text: 'Hôpital', value: HealthCenterType.hopital },
        { text: 'Centre médicaux sociale', value: HealthCenterType.cms }
      ],
      healthCenterTypeStatus: [
        { text: 'Active', value: HealthCenterStatus.Active },
        { text: 'Fermé', value: HealthCenterStatus.Close }
      ]
    }
  },
  computed: {
      ...mapGetters('partner', ['getBirth'])
  },
  methods: {
    ...mapActions('partner', ['updateBirthStatus']),
    ...mapActions('healthCenter', ['createHealthCenter']),
    onHealthCenterCreated(){
      const healthCenter: HealthCenterCreateDto = {
        health_center_name: this.form.healthCenterName,
        health_center_type: this.form.healthCenterType,
        agent_lastname: this.form.agentLastname,
        agent_firstname: this.form.agentFirstname,
        mobile: this.form.mobile,
        township: this.form.township,
        district: this.form.district,
        address_1: this.form.address1,
        address_2: this.form.address2,
        town: this.form.town,
        postal_code: this.form.postalCode,
        country: this.form.country,
        trigramme: this.form.trigramme
      }
      this.createHealthCenter(healthCenter)
      this.$bvModal.hide('partner-admin-create')
    },
    inputHealthCenterNameSet(payload: string){
      this.form.healthCenterName = payload
    },
    inputHealthCenterTypeSet(payload: string){
      this.form.healthCenterType = payload
    },
    inputHealthCenterStatusSet(payload: string){
      this.form.healthCenterStatus = payload
    },
    inputTrigrammeSet(payload: string){
      this.form.trigramme = payload
    },
    inputAgentLastnameSet(payload: string){
      this.form.agentLastname = payload
    },
    inputAgentFirstnameSet(payload: string){
      this.form.agentFirstname = payload
    },
    inputMobileNumberSet(payload: string){
      this.form.mobile = payload
    },
    inputDistrictSet(payload: string){
      this.form.district = payload
    },
    inputTownshipSet(payload: string){
      this.form.township = payload
    },
    inputAddressOneSet(payload: string){
      this.form.address1 = payload
    },
    inputAddressTwoSet(payload: string){
      this.form.address2 = payload
    },
    inputTownSet(payload: string){
      this.form.town = payload
    },
    inputBpSet(payload: string){
      this.form.postalCode = payload
    },
    inputCountrySet(payload: string){
      this.form.country = payload
    },
  },
});
