import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import  { RootState } from './types'
import { layout } from './modules/layout/index';
import { auth } from './modules/auth';
import { routeRedirect } from './modules/route-redirect';
import { partner } from './modules/partner';
import { healthCenter } from './modules/health-center-partner';
import { death } from './modules/death';
import { neighborhood } from './modules/neighborhood';
import { agent } from './modules/agent';

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0'
    },
    modules: {
        layout: layout,
        auth: auth,
        routeRedirect: routeRedirect,
        partner: partner,
        healthCenter: healthCenter,
        death: death,
        neighborhood: neighborhood,
        agent: agent
    },
    devtools: false
}

export default new Vuex.Store<RootState>(store)