
  import {Vue} from 'vue-property-decorator';
  import { TYPE } from "vue-toastification";
  import moment from 'moment';
  import FormSection from '../form-section/form-section.vue'; 
  import FormInput from '@/components/form/form-input/form-input.vue';
  import FormRadio from '@/components/form/form-radio/form-radio.vue';
  import { BirthUpdateDto } from '@/dto/partners/birth.dto';
  import { StatusTypeEnum } from '@/common/enums'
  import {mapGetters, mapActions} from "vuex";
  import { appMixin } from '@/mixins/app.mixin';
import { neighborhood } from '@/store/modules/neighborhood';
  
  export default Vue.extend({
    name: 'BirthRegisterModal',
    props: [
        'id',
        'reference',
        'title',
    ],
    mixins: [appMixin],
    components: {
      FormSection,
      FormInput,
      FormRadio
    },
    data() {
      return {
        data: 4,
        tabIndex: 0,
        value: '',
        sexSelected: '',
        sexOptions: [
          { text: 'Masculin', value: 'male' },
          { text: 'Feminin', value: 'female' }
        ],
        status: '',
        tracked_code: '',
        birthdateFormat: '',
        birthInfo: {
          tracked_code: '',
          status: '',
          health_center: '',
          mother_last_name: '',
          mother_first_name: '',
          mother_phone_number: '',
          other_contact_1: '',
          other_contact_2: '',
          birth_hour: '',
          sex: '',
          midwife: '',
          agent_function: '',
          neighborhood: '',
          address: '',
          declarant: ''
        }
      }
    },
    computed: {
        ...mapGetters('partner', ['getBirth', 'getBirthStat']),
        ...mapGetters('neighborhood', ['getNeighborhoods']),
        ...mapGetters('agent', ['getAgents']),
        declaredAgent(): string{
          const findAgent= this.getAgents.find((agent) => {
            return this.getBirth.declarant === agent.agent_id
          })
          return findAgent.name
        },
        midwife(): string{
          const findAgent= this.getAgents.find((agent) => {
            return this.getBirth.midwife === agent.agent_id
          })
          return findAgent.name
        },
    },
    methods: {
      ...mapActions('partner', ['updateBirthStatus', 'setBirthStat']),
      constructUpdateVar(status: string): BirthUpdateDto{
        let birthStatus = StatusTypeEnum.Rejected
        if(status === 'done'){
          birthStatus = StatusTypeEnum.Done
        }
        return {
          birth_id: this.getBirth.birth_id,
          status: birthStatus
        }
      },
      birthCaseTreated(){
        const birth: BirthUpdateDto = this.constructUpdateVar('done')
        this.updateBirthStatus(birth).then((response)=>{
          if(response===true){
            const birthStat = {
                ...this.getBirthStat,
                pending: this.getBirthStat.pending--,
                done: this.getBirthStat.done++
              }
            this.setBirthStat(
              birthStat
            )
            Vue.$toast("La déclaration de naissance a été traité avec succès!")
          }
          else{
            Vue.$toast("Une erreur s'est produite. "+
            "L'action de traitement de la déclaration de naissance n'a pas été éffectué!", { type: TYPE.ERROR });
          }
        })
        this.$bvModal.hide('birth-register-detail')
      },
      birthCaseClosed(){
        const birth: BirthUpdateDto = this.constructUpdateVar('rejected')
        this.updateBirthStatus(birth).then((response)=>{
          if(response===true){
            Vue.$toast("La déclaration de naissance a été rejeté avec succès!")
          }
          else{
            Vue.$toast("Une erreur s'est produite. "+
            "L'action de clôture de la déclaration de naissance n'a pas été éffectué!", { type: TYPE.ERROR });
          }
        })
        this.$bvModal.hide('birth-register-detail')
      },
      modalStart(){
        const dateCountValue = this.dateCount(this.getBirth.birthdate, this.getBirth.updated_at)
        this.data = dateCountValue > this.MaxDay ? '+'+ this.MaxDay : dateCountValue
        this.tracked_code = this.getBirth.tracked_code
        this.birthdateFormat = moment(this.getBirth.birthdate).format('DD-MM-YYYY')
        this.birthInfo = this.getBirth
      },
      imageLinkClick(){
        const url = this.getBirth.file_uri;
        const newTab = window.open(url, "_blank");
        newTab.focus();
      }
    },
  });
  