import { MutationTree } from 'vuex';
import { PartnerState } from './types';
import { BirthModel, DeclarationStat } from '@/models/partners/birth/birth-model';
import { _BirthUpdateDto } from '@/dto/partners/birth.dto';

export const mutations: MutationTree<PartnerState> = {
  setBirths(state, payload: Array<BirthModel>) {
    state.births = payload;
  },

  _setBirth(state, payload: _BirthUpdateDto){
    const birth: BirthModel = state.births.find(({birth_id}) => birth_id === payload.birth_id)
    state.births.splice(state.births.indexOf(birth), 1, {
      ...birth,
      status: payload.status 
    })
  },

  setBirthStat(state, payload: DeclarationStat){
    state.birthStats = payload
  },

  setBirth(state, payload: BirthModel | undefined) {
    state.birth = payload;
  },

  setBirthFileUri(state, payload: string | undefined) {
    state.birthFileUri = payload;
  },

  setBirthsHealthCenterName(state, payload: Array<BirthModel>){
    state.birthsHealthCenterName = Array.from(new Set(payload.map(item => item.health_center)));
  },

  setStatutFilter(state, payload: string | null){
    state.statutFilter = payload
  },

  setNameFilter(state, payload: string | null){
    state.nameFilter = payload
  },

  setDayLeftFilter(state, payload: number | null){
    state.dayLeftFilter = payload
  },

  setHealthCenterFilter(state, payload: string | null){
    state.healthCenterFilter = payload
  },

};
