import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../components/layout/layout.vue';
import Partners from '../pages/partners/partners.vue';
import BirthRegistration from '../pages/birth-registration/birth-registration.vue';
import DeathRegistration from '../pages/death-registration/death-registration.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {name:'sandbox',path: '/', redirect: '/births'},
  {
    path: '/layout',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/administration',
        name: 'Partners',
        component: Partners
      },
      {
        path: '/births',
        name: 'BirthRegistration',
        component: BirthRegistration
      },
      {
        path: '/deaths',
        name: 'DeathRegistration',
        component: DeathRegistration
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: 'hospi-app/',
  routes
})

export default router
