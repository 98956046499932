import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { AgentState } from './types';
import { agentService } from '@/services/agent.service';
import { AgentModel } from "@/models/partners/agent/agent-model";

export const actions: ActionTree<AgentState, RootState> = {
  loadAgents(context) {
    agentService
      .getInstance(this.getters.getUser.access_token)
      .loadAgents()
      .then((response) => {
        if (response.data) {
          context.dispatch('setAgents', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  setAgents(context, payload: Array<AgentModel>) {
    context.commit('setAgents', payload);
  },

  setAgent(context, payload: AgentModel | undefined) {
    context.commit('setAgent', payload);
  },
};
