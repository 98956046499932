
import { Vue } from "vue-property-decorator";
export default  Vue.extend({
    name: 'StatCard',
    
    props: [
        'icon',
        'value',
        'title',
        'pending',
        'colorClass'
    ],
    data(){
        return {
            data: this.value,
            pendingValue: this.pending
        }
    },
    computed: {
        
    },
    methods: {
    
    },
    watch:{
        value(){
            if(this.value){
                this.data = this.value
            }
        },
        pending(){
            if(this.pending){
                this.pendingValue = this.pending
            }
        }
    }
})
