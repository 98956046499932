import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { PartnerState } from './types'
import { BirthModel, DeclarationStat } from "@/models/partners/birth/birth-model";

export const getters: GetterTree<PartnerState, RootState> = {
    
    getBirths(state): Array<BirthModel> {
        return state.births
    },

    getBirth(state): BirthModel | undefined{
        return state.birth
    },

    getBirthFileUri(state): string | undefined{
        return state.birthFileUri
    },

    getBirthsHealthCenterName(state): Array<string>{
        return state.birthsHealthCenterName
    },

    getStatutFilter(state): string | null{
        return state.statutFilter
    },

    getNameFilter(state): string | null{
        return state.nameFilter
    },

    getDayLeftFilter(state): number | null{
        return state.dayLeftFilter
    },

    getHealthCenterFilter(state): string | null{
        return state.healthCenterFilter
    },

    getBirthStat(state): DeclarationStat | undefined{
        return state.birthStats
    }

};