
import { Vue } from 'vue-property-decorator';
import FormSelect from '@/components/form/form-select/form-select.vue';
import FormInput from '@/components/form/form-input/form-input.vue';
import { mapGetters, mapActions } from 'vuex';
export default Vue.extend({
    name: 'TableFilter',
    props: [
        'filterItems',
        'canCreate'
    ],
    components: {
        FormSelect,
        FormInput
    },
    data() {
        return {
            searchText:'',
            serviceSelected: null,
            categorySelected: null,
            groupSelected: null,
            stateSelected: null,
            hospitalSelected: null,
            registerDateSelected: null,
            perPage: 10,
            serviceOptions: [
                { value: null, text: 'Selectionnez un service' },
                { value: 'service_1', text: 'Service 1' },
                { value: 'service_2', text: 'Service 2' },
                { value: 'service_3', text: 'Service 3' },
            ],
            categoryOptions: [
                { value: null, text: 'Selectionnez une catégorie' },
                { value: 'category_1', text: 'catégorie 1' },
                { value: 'category_2', text: 'catégorie 2' },
                { value: 'category_3', text: 'catégorie 3' },
            ],
            groupOptions: [
                { value: null, text: 'Selectionnez un groupe' },
                { value: 'group_1', text: 'groupe 1' },
                { value: 'group_2', text: 'groupe 2' },
                { value: 'group_3', text: 'groupe 3' },
            ],
            perPageOptions: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 50, text: '50' },
                { value: 100, text: '100' },
            ],
            registerDateOptions: [
                { value: null, text: 'Selectionnez' },
                { value: 10,  text: '10j' },
                { value: 20,  text: '20j' },
                { value: 50,  text: '50j' },
                { value: 100, text: '100j' },
            ],
            stateOptions: [
                { value: null, text: 'Selectionnez un état' },
                { value: 'done', text: 'Traité' },
                { value: 'pending', text: 'En attente' },
                { value: 'rejected', text: 'Rejeté'}
            ],
            hospital: [
                { value: null, text: 'Selectionnez un centre' },
                { value: '1', text: 'CHU Campus' },
                { value: '2', text: 'CHU Tokoin' },
            ]
        }
    },
    computed: {
        ...mapGetters('partner', ['getBirthsHealthCenterName']),
        hospitalOptions(){
            const options = [
                {
                    value: null,
                    text: 'selectionner un centre'
                }
            ]
            this.getBirthsHealthCenterName.forEach(element => {
                options.push({
                    value: element,
                    text: element
                })
                
            });
            return options
        }
    },
    methods: {
        showing() {
            this.$emit('createBtnClicked')
        },
        onStateSet(payload: string){
            this.$emit('onStateInputSet', payload)
        },
        onSearch(payload: string){
            this.$emit('onSearchInputSet', payload)
        },
        onDayLeftSet(payload: string){
            this.$emit('onDayLeftInputSet', payload)
        },
        onHealthCenterSet(payload: string){
            this.$emit('onHealthCenterSet', payload)
        }
    }
});
