import {Tokens} from "@/plugins/shield/models/token";
import {User} from "@/plugins/shield/models/user";
import utils from "@/plugins/shield/utils/utils";
import store from '@/store';

export class Context {
  private contextTokens: Tokens

  constructor(tokens: any) {
    this.contextTokens = tokens
      store.commit('setUser', this.getUser())
  }

  getExpiresIn(): number {
    return this.contextTokens.expires_in
  }

  getContext(): Tokens {
    return this.contextTokens
  }

  getUser(): User {
    const parsedToken = utils.parseJWT(this.contextTokens.token)
    return {
        username: parsedToken.preferred_username,
        name: parsedToken.name,
        email: parsedToken.email,
        tenant: parsedToken.tenant,
        access_token: this.contextTokens.token,
        roles: parsedToken.realm_access,
        resource_access: parsedToken.resource_access
    }
  }

}