import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { PartnerState } from './types';
import { partnerService } from '@/services/partner.service';
import { BirthModel, DeclarationStat } from '@/models/partners/birth/birth-model';
import { BirthUpdateDto, _BirthUpdateDto } from '@/dto/partners/birth.dto';
import { documentGeneratorService } from '@/services/docGenerator.service';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";


export const actions: ActionTree<PartnerState, RootState> = {
  loadBirths(context) {
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .loadBirths(this.getters.getUser.tenant)
      .then((response) => {
        if (response.data) {
          context.dispatch('setBirths', response.data)
          context.dispatch('loadBirthStats')
          context.commit('setBirthsHealthCenterName', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadFileUri(context, payload: string) {
    documentGeneratorService
      .getInstance(this.getters.getUser.access_token)
      .loadFileUri(payload)
      .then((response) => {
        if (response.data) {
          window.open(response.data.document_url, '_blank');
          return response
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadBirthStats(context) {
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .loadBirthStats()
      .then((response) => {
        if (response.data) {
          context.dispatch('setBirthStat', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  updateBirthStatus(context, payload: BirthUpdateDto){
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .updateBirthStatus(payload)
      .then((response) => {
        if (response.apiError) {
          return false
        }else{
          context.dispatch('loadBirthStats')
          const birth: _BirthUpdateDto = {
            birth_id: payload.birth_id,
            status: payload.status
          } 
          context.commit('_setBirth', birth)
          return true
        }
      });
  },

  downloadImage(context, payload:string){
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .downloadImage(payload)
      .then((response) => {
        if (response.data) {
          console.log(response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  setBirths(context, payload: Array<BirthModel>) {
    context.commit('setBirths', payload);
  },

  setBirth(context, payload: BirthModel | undefined) {
    context.commit('setBirth', payload);
  },

  setBirthStat(context, payload: DeclarationStat){
    context.commit('setBirthStat', payload);
  },

  setStatutFilter(context, payload: string | null){
    context.commit('setStatutFilter', payload);
  },

  setNameFilter(context, payload: string | null){
    context.commit('setNameFilter', payload);
  },

  setDayLeftFilter(context, payload: number | null){
    context.commit('setDayLeftFilter', payload);
  },

  setHealthCenterFilter(context, payload: string | null){
    context.commit('setHealthCenterFilter', payload);
  },
};
