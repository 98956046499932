import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/layout/layout-getters";
import {actions} from "@/store/modules/layout/layout-actions";
import {mutations} from "@/store/modules/layout/layout-mutations";
import { LayoutState } from "./types";
export const state: LayoutState = {
    activeSidebar: true,
    sidebarActiveElement: "O",
};

export const layout: Module<LayoutState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};