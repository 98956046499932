
import {Vue} from 'vue-property-decorator';
import {mapGetters, mapActions} from "vuex";

export default Vue.extend({
name: 'ImagePreviewModal',
props: [
    'id',
    'reference',
    'title',
],
components: {
    
},
mixins: [
    
],
data() {
    return {
        file_uri: '',
        file_name: ''
    }
},
computed: {
    ...mapGetters('partner', ['getBirth'])
},
methods: {
    ...mapActions('partner', ['updateBirthStatus', 'downloadImage']),
    download(){
        this.downloadImage(this.getBirth.media_id)
    },
    modalStart(){
        if(this.getBirth){
            this.file_uri = this.getBirth.file_uri,
            this.file_name = this.getBirth.file_name
        }
    }
},
});
