import { MutationTree } from 'vuex';
import { NeighborhoodState } from './types';
import { NeighborhoodUpdateDto } from '@/dto/partners/neighborhood.dto';
import { NeighborhoodModel } from '@/models/partners/neighborhood/neighborhood-model';

export const mutations: MutationTree<NeighborhoodState> = {
  setNeighborhoods(state, payload: Array<NeighborhoodModel>) {
    state.neighborhoods = payload;
  },

  _setNeighborhoods(state, payload: NeighborhoodModel | undefined){
    state.neighborhoods.push(payload)
  },

  _setNeighborhood(state, payload: NeighborhoodUpdateDto){
    const neighborhood: NeighborhoodModel = state.neighborhoods.find(({neighborhood_id}) => neighborhood_id === payload.neighborhood_id)
    state.neighborhoods.splice(state.neighborhoods.indexOf(neighborhood), 1, {
      ...neighborhood,
      neighborhood_name: payload.neighborhood_name 
    })
  },

  setNeighborhood(state, payload: NeighborhoodModel | undefined) {
    state.neighborhood = payload;
  },

  setNameFilter(state, payload: string | null){
    state.nameFilter = payload
  },
};
