import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/neighborhood/getters";
import {actions} from "@/store/modules/neighborhood/actions";
import {mutations} from "@/store/modules/neighborhood/mutations";
import { NeighborhoodState } from "./types";
export const state: NeighborhoodState = {
    neighborhoods: [],
    neighborhood: undefined,
    nameFilter: null,
};

export const neighborhood: Module<NeighborhoodState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};