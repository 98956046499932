import { MutationTree } from 'vuex';
import { LayoutState } from "./types";

export const mutations: MutationTree<LayoutState> = {

    setActiveSidebar(state, payload: boolean) {
        state.activeSidebar = payload
    },

    setSidebarActiveElement(state, payload: string) {
        state.sidebarActiveElement = payload
    },
};
