
import { Vue } from 'vue-property-decorator';
import moment from 'moment';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import birthRegister from '@/json/birth_register.json';
import BirthRegisterModal from '@/components/modals/partners/birth-register-modal.vue'
import ImagePreviewModal from '@/components/modals/partners/image-preview-modal/image-preview-modal.vue';
import StatContainer from '@/components/stat-container/stat-container.vue';
import StatCard from '@/components/stat-container/stat-card/stat-card.vue';
import { BirthModel, DeclarationStat } from '@/models/partners/birth/birth-model';
import { mapGetters, mapActions } from 'vuex';
import { appMixin } from '@/mixins/app.mixin';
export default Vue.extend({
    name: 'BirthRegistration',
    components: {
        TableFilter,
        TableView,
        BirthRegisterModal,
        ImagePreviewModal,
        StatContainer,
        StatCard
    },
    mixins: [appMixin],
    data() {
        return {
            birthRegisters: birthRegister,
            fields: [
                { key: "tracked_code", label: "Numéro de déclaration", sortable: false, tdClass: 'ellipsis', thClass: 'ellipsis'},
                { key: "health_center", label: "Centre santé", sortable: false, tdClass: 'ellipsis', thClass: 'ellipsis'},
                { 
                    key: "mother", label: "Mère accoucheuse", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.mother_last_name +' '+ item.mother_first_name
                    },
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { key: "mother_phone_number", label: "Téléphone", sortable: false },
                { 
                    key: "birthdate", label: "Date d'accouchement", sortable: false,
                    formatter: (value: null, key: string, item: any) => {
                        return moment(item.birthdate).format('DD-MM-YYYY')
                    },
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { 
                    key: "register_since", label: "Depuis", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return moment().diff(moment(item.birthdate, 'DD-MM-YYYY'), 'days')
                    },
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { 
                    key: "register_status", label: "Statut", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.status
                    }, 
                    tdClass: 'ellipsis', thClass: 'ellipsis' 
                },
                { key: "file_name", label: "Document", sortable: false, },
            ],
            processFilter: {
                hospital: 'Centre de santé',
                state: 'Etat',
                registerDate: 'Date de déclaration'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed:{
        ...mapGetters('partner', ['getBirths', 'getNameFilter', 'getStatutFilter',
                        'getDayLeftFilter', 'getHealthCenterFilter', 'getBirthStat']),
        filteredItems() {
            const filtered = this.getBirths.filter((item) => {
                // Conditions de filtrage basées sur statusFilter, nameFilter, daysFilter
                const format = moment(item.created_at).format('DD-MM-YYYY')
                return (
                    (!this.getStatutFilter || item.status === this.getStatutFilter) &&
                    (
                        !this.getNameFilter || this.getNameFilter.trim() === '' ||
                        item.health_center.toLowerCase().includes(this.getNameFilter) ||
                        item.tracked_code.toLowerCase().includes(this.getNameFilter) ||
                        item.mother_last_name.toLowerCase().includes(this.getNameFilter) ||
                        item.mother_first_name.toLowerCase().includes(this.getNameFilter)

                    ) && 
                    (!this.getDayLeftFilter || moment().diff(moment(format, 'DD-MM-YYYY'), 'days') <= this.getDayLeftFilter) &&
                    (!this.getHealthCenterFilter || item.health_center === this.getHealthCenterFilter)
                );
            });
            return filtered;
        },
        total(): number{
            if(this.getBirthStat !== undefined){
                return this.getBirthStat.pending + this.getBirthStat.done 
                    + this.getBirthStat.no_continuation + this.getBirthStat.rejected
            }
            return 0
        },
        stats(): DeclarationStat{
            if(this.getBirthStat !== undefined){
                return this.getBirthStat
            }
            return {
                pending: 0,
                done: 0,
                rejected: 0,
                no_continuation: 0
            }
        },
    },
    methods: {
        ...mapActions('partner', ['loadBirths','setBirth','setStatutFilter', 'setNameFilter',
                                    'setDayLeftFilter', 'setHealthCenterFilter', 'loadFileUri']),
        showBirthRegisterDetail(payload: BirthModel){
            this.setBirth(payload)
            this.$bvModal.show('birth-register-detail')
        },
        imageLinkClicked(payload: BirthModel){
            this.setBirth(payload)
            this.$bvModal.show('image-preview-detail')
        },
        onSearchSet(payload: string){
            this.setNameFilter(payload.toLowerCase())
        },
        onDayLeftSet(payload: string){
            this.setDayLeftFilter(payload)
        },
        onStateSet(payload: string){
            this.setStatutFilter(payload)
        },
        onHealthCenterFilter(payload: string){
            this.setHealthCenterFilter(payload)
        },
        openInNewBlank(payload: BirthModel){
            this.loadFileUri(payload.tracked_code)
        }
    },
    mounted(){
      this.loadBirths()
    }
});
