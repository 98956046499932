import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/death/getters";
import {actions} from "@/store/modules/death/actions";
import {mutations} from "@/store/modules/death/mutations";
import { DeathState } from "./types";
export const state: DeathState = {
    deaths: [],
    death: undefined,
    deathStats: undefined,
    statutFilter: null,
    nameFilter: null,
    dayLeftFilter: null,
    healthCenterFilter: null,
    deathsHealthCenterName: [],
    deathFileUri: undefined
};

export const death: Module<DeathState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};