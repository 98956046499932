import { MutationTree } from 'vuex';
import { DeathState } from './types';
import { DeathModel } from '@/models/partners/death/death-model';
import { _DeathUpdateDto } from '@/dto/partners/death.dto';
import { DeclarationStat } from '@/models/partners/birth/birth-model';

export const mutations: MutationTree<DeathState> = {
  setDeaths(state, payload: Array<DeathModel>) {
    state.deaths = payload;
  },

  _setDeath(state, payload: _DeathUpdateDto){
    const death: DeathModel = state.deaths.find(({death_id}) => death_id === payload.death_id)
    state.deaths.splice(state.deaths.indexOf(death), 1, {
      ...death,
      status: payload.status 
    })
  },

  setDeath(state, payload: DeathModel | undefined) {
    state.death = payload;
  },

  setDeathFileUri(state, payload: string | undefined) {
    state.deathFileUri = payload;
  },

  setDeathsHealthCenterName(state, payload: Array<DeathModel>){
    state.deathsHealthCenterName = Array.from(new Set(payload.map(item => item.health_center)));
  },

  setStatutFilter(state, payload: string | null){
    state.statutFilter = payload
  },

  setNameFilter(state, payload: string | null){
    state.nameFilter = payload
  },

  setDayLeftFilter(state, payload: number | null){
    state.dayLeftFilter = payload
  },

  setHealthCenterFilter(state, payload: string | null){
    state.healthCenterFilter = payload
  },

  setDeathStat(state, payload: DeclarationStat){
    state.deathStats = payload
  },

};
