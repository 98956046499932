
import { Vue } from "vue-property-decorator";

import { mapGetters, mapActions } from 'vuex'
import { DeclarationStat } from "@/models/partners/birth/birth-model";
export default  Vue.extend({
    name: 'StatContainer',
    components: {
        
    },
    data() {
        return {
        }
    },
    computed: { 
        ...mapGetters('partner', ['getBirthStat'])
    },
    methods: {
    },
})
