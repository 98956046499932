import { render, staticRenderFns } from "./birth-register-modal.vue?vue&type=template&id=43ea5a50&scoped=true"
import script from "./birth-register-modal.vue?vue&type=script&lang=ts"
export * from "./birth-register-modal.vue?vue&type=script&lang=ts"
import style0 from "./birth-register.scss?vue&type=style&index=0&id=43ea5a50&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43ea5a50",
  null
  
)

export default component.exports