import { HttpBaseService } from '@/services/httpBase.service';
import { documentGeneratorUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { DocumentModel } from '@/models/document/document';


export class documentGeneratorService extends HttpBaseService {
  private static classInstance?: documentGeneratorService;
  static apiBaseUrl = documentGeneratorUrl;

  constructor(token: string) {
    super(token, documentGeneratorService.apiBaseUrl);
  }

  public static getInstance(token: string): documentGeneratorService {
    if (!this.classInstance) {
      this.classInstance = new documentGeneratorService(token);
    }

    return this.classInstance;
  }


  public async loadFileUri(payload: string): Promise<ApiResponse<DocumentModel>> {
    const apiResponse = new ApiResponse<DocumentModel>();
    return await this.instance
      //.get(`/media/${payload}`)
      .get(`/load?document=${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

}