import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/health-center-partner/health-center-partner-getters";
import {actions} from "@/store/modules/health-center-partner/health-center-partner-action";
import {mutations} from "@/store/modules/health-center-partner/health-center-partner-mutations";
import { HealthCenterState } from "./types";
export const state: HealthCenterState = {
    healthCenters: [],
    healthCenter: undefined,
    nameFilter: null,
};

export const healthCenter: Module<HealthCenterState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};