
import moment from "moment";
import { Vue } from "vue-property-decorator";
import { appMixin } from "@/mixins/app.mixin";
import { mapActions } from 'vuex';

export default Vue.extend({
  name: "TableView",
  props: [
    'tableItems', 'fixed', 'fields', 'filter', 'id',
    'currentPage', 'perPage', 'detailRow', 'rowSelection', 
    'theAnotherPage', 'tableConfig', 'filterIncludedFields',
    'tableName'
  ],
  mixins: [appMixin],
  components:{
  },
  data() {
    return {
      rowSelected: "",
      transProps: {
        // Transition name
        name: "flip-list",
      },
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('partner', ['loadFileUri']),
    onFiltered (filteredItems: Record<string, unknown>[]) {
      this.$emit('onFiltered', filteredItems)
    },
    onRowClicked(item: any) {
      if (this.tableName === 'process') {
        this.goToAnotherPage(this.theAnotherPage)
      } else { 
        this.$emit('onTableRowClicked', item)
      }
    },
    openInNewBlank(item: any){
      this.$emit('onOpenInNewBlank', item)
    },
    goToAnotherPage(page: string) {
      this.$router.push(page);
    },
    openProcessList(item: Record<string, unknown>){
      this.$emit('onProcessList', item)
    },
    openGroupMemberList(item: Record<string, unknown>){
      this.$emit('onMemberList', item)
    },
    imagePreviewCliked(payload: any){
      this.$emit('imagePreview', payload)
    },
    testClicked(item: any){
      this.$emit('onPensilClicked', item)
    },
  },
});
