import { HttpBaseService } from '@/services/httpBase.service';
import { partnerServiceApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { BirthModel, DeclarationStat } from '@/models/partners/birth/birth-model';
import { BirthUpdateDto } from '@/dto/partners/birth.dto';
import { HealthCenterCreateDto, HealthCenterUpdateDto } from '@/dto/partners/health-center.dto';
import { HealthCenterModel } from '@/models/partners/health-center/health-center-model';


export class partnerService extends HttpBaseService {
  private static classInstance?: partnerService;
  static apiBaseUrl = partnerServiceApiUrl;

  constructor(token: string) {
    super(token, partnerService.apiBaseUrl);
  }

  public static getInstance(token: string): partnerService {
    if (!this.classInstance) {
      this.classInstance = new partnerService(token);
    }

    return this.classInstance;
  }

  public async loadBirths(payload: string): Promise<ApiResponse<[]>> {
    const apiResponse = new ApiResponse<[]>();
    const requestParam = {
      "tenant": payload,
      "health_center_code": ""
    }
    return await this.instance
      .post('/birth/search', requestParam)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadFileUri(payload: string): Promise<ApiResponse<any>> {
    const apiResponse = new ApiResponse<any>();
    return await this.instance
      .get(`/media/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadBirthStats(): Promise<ApiResponse<DeclarationStat>> {
    const apiResponse = new ApiResponse<DeclarationStat>();
    return await this.instance
      .get('/birth/global-status')
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadBirthByTrackedCode(payload: string): Promise<ApiResponse<BirthModel>> {
    const apiResponse = new ApiResponse<BirthModel>();
    return this.instance
      .get(`/birth/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public updateBirthStatus(payload: BirthUpdateDto): Promise<ApiResponse<BirthModel>> {
    const apiResponse = new ApiResponse<BirthModel>();
    return this.instance
      .put('/birth/update', payload)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public downloadImage(payload: string): Promise<ApiResponse<BirthModel>> {
    const apiResponse = new ApiResponse<BirthModel>();
    return this.instance
      .get(`/media/download/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public createHealthCenterPartner(payload: HealthCenterCreateDto): Promise<ApiResponse<HealthCenterModel>> {
    const apiResponse = new ApiResponse<HealthCenterModel>();
    return this.instance
      .post('/health-center/create-health-center', payload)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public updateHealthCenter(payload: HealthCenterUpdateDto): Promise<ApiResponse<HealthCenterModel>> {
    const apiResponse = new ApiResponse<HealthCenterModel>();
    //const data = qs.stringify(payload);
    return this.instance
      .put('/health-center/update', payload)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadHealthCenters(): Promise<ApiResponse<HealthCenterModel[]>> {
    const apiResponse = new ApiResponse<HealthCenterModel[]>();
    return await this.instance
      .get('/health-center/get-health-centers')
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadHealthCenterById(payload: string): Promise<ApiResponse<HealthCenterModel>> {
    const apiResponse = new ApiResponse<HealthCenterModel>();
    return this.instance
      .get(`/health-center/get-health-center/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public deleteHealthCenter(payload: string): Promise<ApiResponse<HealthCenterModel>> {
    const apiResponse = new ApiResponse<HealthCenterModel>();
    return this.instance
      .delete(`/health-center/delete/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

}
